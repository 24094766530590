<template>
  <common-modal @close="$emit('close')" height="h-max">
    <div class="-full">
      <div class="flex justify-between items-center">
        <h1 class="text-gray-900 font-semibold text-2xl">Edit Profile</h1>
        <div class="flex items-center text-grey-500 space-x-1 select-none">
          <img
            @click="$emit('rotate-profile', true)"
            src="@/assets/images/icons/back-arrow.svg"
            class="h-4 w-4 icon-button" />

          <p class="text-sm">Next Profile</p>

          <img
            @click="$emit('rotate-profile')"
            src="@/assets/images/icons/forward-arrow.svg"
            class="h-4 w-4 icon-button" />
        </div>
      </div>
      <div class="h-20 my-7 flex items-center relative">
        <span>
          <div
            v-if="profileImage"
            class="flex-shrink-0 rounded-full overflow-hidden">
            <img :src="profileImage" class="w-20 h-20 object-cover" />
          </div>
          <div
            v-else
            class="w-20 h-20 bg-grey-500 rounded-full whitespace-nowrap" />
          <input
            type="file"
            @change="selectFile($event)"
            accept="image/png,image/jpg,image/jpeg"
            name="file"
            id="file"
            ref="fileupload"
            class="w-0 h-0 hidden" />
          <div
            class="absolute text-xs cursor-pointer left-14"
            @click="profilePictureClick">
            Edit
          </div>
        </span>
        <h1 class="ml-4 text-gray-700 font-semibold text-xl">
          {{ user.firstName }} {{ user.lastName }}
        </h1>
      </div>
      <div class="w-full flex flex-col space-y-4 max-h-96 overflow-auto">
        <div class="flex w-full space-x-4">
          <div key="first-name" class="w-full">
            <p class="mb-2 text-sm text-grey">First Name</p>
            <common-input v-model="firstName" />
          </div>

          <div key="last-name" class="w-full">
            <p class="mb-2 text-sm text-grey">Last Name</p>
            <common-input v-model="lastName" />
          </div>
        </div>

        <div
          v-for="(field, index) of fieldNames"
          :key="`profile-field-${index}`">
          <p class="mb-2 text-sm text-grey">{{ field }}</p>
          <common-textarea v-model="fieldAnswers[index]" inputHeight />
        </div>
      </div>
      <div class="mt-12 flex justify-end">
        <common-button
          variant="primary"
          :disabled="isDisabled"
          :loading="saving"
          width="w-40"
          @click="saveProfile">
          Save
        </common-button>
      </div>
    </div>
  </common-modal>
</template>

<script>
  import { mapGetters } from "vuex";
  import { GET_BRANDING } from "@/store/getters.type";
  import { SET_AMBASSADOR } from "@/store/actions.type";
  import { resizeImage } from "@/shared/utils";

  export default {
    name: "edit-profile-modal",

    props: ["user"],

    data() {
      return {
        fieldNames: [],
        fieldAnswers: [],
        profileImage: null,
        firstName: "",
        lastName: "",
        saving: false,
      };
    },

    computed: {
      isDisabled() {
        return (
          (this.fieldAnswers[0] && !this.fieldAnswers[0].length) ||
          (this.fieldAnswers[1] && !this.fieldAnswers[1].length) ||
          (this.fieldAnswers[2] && !this.fieldAnswers[2].length) ||
          (this.fieldAnswers[3] && !this.fieldAnswers[3].length) ||
          this.saving
        );
      },

      ...mapGetters({ getBranding: GET_BRANDING }),
    },

    watch: {
      user: {
        deep: true,
        handler() {
          this.setUserInfo();
        },
      },
    },

    created() {
      this.setUserInfo();
      this.fieldNames = this.getBranding.signUpFields;
    },

    methods: {
      profilePictureClick() {
        this.$refs["fileupload"].click();
      },

      selectFile(event) {
        if (!event.target.files) {
          return;
        }

        const image = event.target.files[0];
        const fileSize = event.target.files[0].size;
        const reader = new FileReader();

        const PROFILE_IMAGE_SIZE = 200;

        // downsize and compress if image > 100 KB
        if (fileSize > 100000) {
          reader.readAsArrayBuffer(image);
          reader.onload = async () => {
            this.profileImage = await resizeImage(
              reader.result,
              PROFILE_IMAGE_SIZE,
              "image/jpeg"
            );
          };
        } else {
          reader.readAsDataURL(image);
          reader.onload = () => {
            this.profileImage = reader.result;
          };
        }
      },

      async saveProfile() {
        if (this.isDisabled) {
          return;
        }

        this.saving = true;

        const data = {
          id: this.user.id,
          profile: this.fieldAnswers,
          first: this.firstName,
          last: this.lastName,
        };

        const regex = new RegExp(/^data/);
        if (this.profileImage && this.profileImage.match(regex)) {
          data.profileImage = this.profileImage;
        }

        const response = await this.$store.dispatch(SET_AMBASSADOR, data);
        if (response) {
          this.$toasted.show(`Profile saved.`, this.$toastedSuccess);
        } else {
          this.$toasted.show(
            `There was an issue saving this profile.`,
            this.$toastedFailure
          );
        }

        this.saving = false;
      },

      setUserInfo() {
        this.firstName = this.user.firstName;
        this.lastName = this.user.lastName;
        this.fieldAnswers = this.user.profile
          ? JSON.parse(JSON.stringify(this.user.profile))
          : { 0: "", 1: "", 2: "", 3: "" };
        this.profileImage = this.user.profileImage;
      },
    },
  };
</script>
